import { Badge } from '@getro/rombo';
import { ListFilter } from 'lucide-react';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { useContactsFilterCount } from 'hooks/useContactsFilterCount';
import { activeNetworkSelector } from '../../../redux/selectors';
import { Filters } from './filters';
import { FiltersModal } from './filtersModal';
import { defaultFilters } from './defaultFilters';
import { trackContactFiltersEvent } from './utils/trackFilterEvent';

export const ContactsFilters = memo(
  ({ filters, setFilters, extendedMode, trackingLocation, sharedList, showListFilters, filtersTitle }) => {
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const activeNetwork = useSelector(activeNetworkSelector);
    const filterCount = useContactsFilterCount(filters);

    const setMultipleFilters = (group, options = {}) => {
      const { removeFilters = [] } = options;
      const cleanFilters = Object.keys(group).reduce((arr, key) => {
        if (group[key]?.value === 'any') {
          removeFilters.push(key);
          return arr;
        }

        arr[key] = group[key]; // eslint-disable-line no-param-reassign
        return arr;
      }, {});
      const newFilters = { ...filters, ...cleanFilters };

      [...new Set(removeFilters)].forEach((item) => {
        delete newFilters[item];
      });

      setFilters(newFilters);

      trackContactFiltersEvent({
        group,
        activeNetwork,
        trackingLocation,
        sharedList,
        newFilters,
      });
    };

    const onClickFilterCta = () => {
      setShowFiltersModal(true);
    };

    const clearFundFilters = () => {
      setFilters({
        ...filters,
        stageTransitions: [],
        activePositionsOrganizationStages: [],
        companyStages: [],
      });
    };

    return (
      <Box
        data-testid="contact-filters"
        pt="16px"
        px={[0, 0, '16px']}
        sx={{
          mb: ['40px', '40px', 0],
          position: ['relative', 'relative', 'sticky'],
          top: 0,
          height: ['auto', 'auto', '100vh'],
          overflowY: ['visible', 'visible', 'auto'],
        }}
      >
        <Box
          onClick={onClickFilterCta}
          sx={{
            width: '100%',
            minWidth: '100%',
            minHeight: '48px',
            cursor: 'pointer',
            p: '0px 16px',
            borderRadius: 'default',
            border: 1,
            borderColor: 'border.subtle',
          }}
          display={['flex', 'flex', 'none']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex data-testid="contact-filters-title" sx={{ gap: '8px' }} alignItems="center">
            <Box as={ListFilter} width="16px" height="16px" aria-hidden="true" />
            <Text fontWeight="500" color="text.dark">
              {filtersTitle}
            </Text>
          </Flex>

          {filterCount > 0 ? (
            <Box>
              <Badge
                sx={{
                  color: 'purple.400',
                  backgroundColor: 'purple.100',
                  fontWeight: '700',
                  boxShadow: 'none',
                  fontSize: '13px',
                  lineHeight: '16px',
                  height: '24px',
                  width: '24px',
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {filterCount}
              </Badge>
            </Box>
          ) : null}
        </Box>
        {showFiltersModal && (
          <FiltersModal
            sharedList={sharedList}
            filters={filters}
            setFilters={setMultipleFilters}
            extendedMode={extendedMode}
            isTalentNetworkEnabled={!activeNetwork?.tnHidden}
            onClose={() => setShowFiltersModal(false)}
            resetFilters={() => setFilters(defaultFilters)}
            showListFilters={showListFilters}
            clearFundFilters={clearFundFilters}
          />
        )}
        {!!filtersTitle && (
          <Box
            display={['none', 'none', 'flex']}
            sx={{
              color: 'text.subtle',
              fontSize: '12px',
              textTransform: 'uppercase',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text
              data-testid="contact-filters-title"
              sx={{
                textTransform: 'none',
                color: 'text.dark',
                fontWeight: 600,
                lineHeight: '1.4',
                fontSize: '18px',
                height: '40px',
              }}
            >
              {filtersTitle}
            </Text>
          </Box>
        )}
        <Box display={['none', 'none', 'block']} pb="70px">
          <Filters
            sharedList={sharedList}
            filters={filters}
            filterCount={filterCount}
            setFilters={setMultipleFilters}
            extendedMode={extendedMode}
            isTalentNetworkEnabled={!activeNetwork?.tnHidden}
            showListFilters={showListFilters}
            clearFundFilters={clearFundFilters}
          />
        </Box>
      </Box>
    );
  },
);

ContactsFilters.propTypes = {
  sharedList: PropTypes.object,
  extendedMode: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  trackingLocation: PropTypes.oneOf(['admin_portal', 'shared_list']),
  showListFilters: PropTypes.bool,
  filtersTitle: PropTypes.string,
};

ContactsFilters.defaultProps = {
  sharedList: null,
  extendedMode: false,
  trackingLocation: 'admin_portal',
  showListFilters: true,
  filtersTitle: '',
};
