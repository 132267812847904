import { useGetMatchListQuery } from 'services/matches';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useState } from 'react';
import { useGetJobsQuery } from 'services/connectJobs';

export const useJobForList = () => {
  const [keywords, setKeywords] = useState();
  const [debouncedKeywords, setDebouncedKeywords] = useState();
  const [page, setPage] = useState(1);
  const {
    data: matchSuggestionsList,
    isLoading: isLoadingMatchSuggestionsList,
    isFetching: isFetchingSuggestionsList,
  } = useGetMatchListQuery(
    {
      params: {
        page,
        suggested: true,
      },
      providesTags: ['match-list'],
    },
    {
      skip: Boolean(keywords?.length),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: jobData,
    isFetching: isFetchingJobs,
    error: jobError,
  } = useGetJobsQuery(
    {
      keywords: debouncedKeywords,
    },
    {
      skip:
        isLoadingMatchSuggestionsList ||
        !matchSuggestionsList?.loaded ||
        (matchSuggestionsList?.items?.length && matchSuggestionsList?.loaded && !debouncedKeywords),
    },
  );

  useDebouncedEffect(
    () => {
      if (keywords) {
        setDebouncedKeywords(keywords);
      }
    },
    600,
    keywords,
  );

  const normalizedJobData = jobData
    ? jobData.map((job) => ({
        organization: job.organization,
        job: { id: job.id, title: job.title },
        matches: job.contacts || [],
        matchList: { id: null, status: job.status || 'none' },
      }))
    : [];
  const normalizedSuggestionsData = matchSuggestionsList?.items
    ? matchSuggestionsList.items.map((suggestion) => ({
        organization: suggestion.organization,
        job: suggestion.job,
        matches: suggestion.matches.contacts,
        matchList: { id: suggestion.id, status: suggestion.status },
      }))
    : [];

  return {
    isLoadingMatchSuggestionsList,
    matchSuggestionsList,
    isLoadingJobs: isFetchingJobs,
    jobs: jobData,
    search: setKeywords,
    searchResults: keywords || !normalizedSuggestionsData.length ? normalizedJobData : normalizedSuggestionsData,
    showEmptyState: jobData?.length === 0 && normalizedSuggestionsData?.length === 0,
    error: jobError,
    keywords,
    isLoadingMoreMatches: Boolean(matchSuggestionsList?.items?.length) && isFetchingSuggestionsList,
    hasMoreMatches: keywords ? false : matchSuggestionsList?.hasMore,
    total: matchSuggestionsList?.meta?.totalMatchLists,
    loadMore: () => {
      if (matchSuggestionsList?.hasMore) {
        setPage(page + 1);
      }
    },
  };
};
