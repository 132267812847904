import { trackEvent } from '../../../../helpers/analytics';
import { parseFilterValueForTrackingEvents } from '../../../../services/contacts/filters';

export const trackContactFiltersEvent = ({ group, newFilters, activeNetwork, trackingLocation, sharedList }) => {
  const filterNames = new Set([
    'jobTitles',
    'locationIds',
    'companyName',
    'companyStages',
    'currentCompanySizes',
    'industryTags',
    'skills',
    'sharedListIds',
    'tags',
    'importerIds',
    'yearsOfExperience',
    'yearsInCurrentCompany',
    'keywords',
    'inPortco',
    'hasSignupInfo',
    'signupJobFunctions',
    'signupSeniorities',
    'signupWorkEnvironments',
    'signupLocations',
    'signupJobSearchStatuses',
    'signupHasResume',
    'signupDaysSinceJoined',
  ]);

  const mapper = {
    jobTitles: new Set(['jobTitlesIncluded', 'jobTitlesMatch']),
    stageTransitions: new Set(['stageTransitions', 'activePositionsOrganizationStages']),
    industryTagsYears: new Set(['industryTagsYears']),
    companyName: new Set(['companyNameIncluded', 'companyNamePeriod']),
    locationIds: new Set(['locationIds']),
  };
  const reverseMapper = new Map([
    ['jobTitlesIncluded', 'jobTitles'],
    ['jobTitlesMatch', 'jobTitles'],
    ['stageTransitions', 'stageTransitions'],
    ['activePositionsOrganizationStages', 'stageTransitions'],
    ['industryTagsYears', 'industryTagsYears'],
    ['companyNameIncluded', 'companyName'],
    ['companyNamePeriod', 'companyName'],
  ]);

  const eventPayload = {};

  Object.keys(group).forEach((key) => {
    const filterName = filterNames.has(key) ? key : reverseMapper.get(key);
    eventPayload.filterName = filterName;
    eventPayload.selectedValues = parseFilterValueForTrackingEvents(filterName, newFilters[filterName]);
    const options = Object.keys(newFilters).filter((filterKey) => mapper[key]?.has?.(filterKey));
    if (options.length) {
      eventPayload.options = options;
    }
  });

  trackEvent('contacts:filter_change', {
    collection_id: activeNetwork.id,
    ...eventPayload,
    page: trackingLocation,
    ...(sharedList ? { sharedList: sharedList.id } : undefined),
  });
};
