import { Briefcase, Layout, Server, Settings as SettingsIcon, TrendingUp, User, Users } from 'lucide-react';
import PlansAndBilling from 'pages/Settings/plansAndBilling';
import ListDetail from 'pages/listDetail';
import { JobBoardAppearance } from 'pages/Settings/jobBoardAppearance';
import AccountTab from '../pages/Account/AccountTab';
import { Contacts } from '../pages/contacts';
import { ContactsAddSingleContact } from '../pages/contactsAddSingleContact';
import { ContactsEditSingleContact } from '../pages/contactsEditSingleContact';
import { ContactsAddSingleContactError } from '../pages/contactsAddSingleContactError';
import { ContactsImport } from '../pages/contactsImport';
import { ContactsImportSuccess } from '../pages/contactsImportSuccess';
import { ContactsImportCsv } from '../pages/contactsImportCsv';
import { ContactsUpgrade } from '../pages/contactsUpgrade';
import { ContactsSharedListsSlackSubscribe } from '../pages/contactsSharedListsSlackSubscribe';
import { ContactsSharedListsUnsubscribe } from '../pages/contactsSharedListsUnsubscribe';
import { ContactsLinkedInSync } from '../pages/contactsLinkedInSync';
import { Hires } from '../pages/hires';
import ProfileTab from '../pages/Account/ProfileTab';
import Analytics from '../pages/Analytics';
import ClaimYourProfile from '../pages/ClaimYourProfile';
import CompanyProfile from '../pages/CompanyProfile';
import EmployerOnboarding from '../pages/EmployerOnboarding';
import Employers from '../pages/Employers';
import Inbox from '../pages/Inbox';
import Job from '../pages/Job';
import Login from '../pages/Login';
import { LogOut } from '../pages/logOut';
import MembersInvite from '../pages/MembersInvite';
import NetworkHome from '../pages/NetworkHome';
import Settings from '../pages/Settings/general';
import Admins from '../pages/Settings/admins';
import SettingsContacts from '../pages/Settings/contacts';
import RecoverPassword from '../pages/RecoverPassword';
import memberRoutes from './membersRoutes';
import bulkMembersInviteRoutes from './bulkMembersInviteRoutes';
import addCompanyRoutes from './addCompanyRoutes';
import ManageSubscriptions from '../pages/manageSubscriptions';
import Share from '../pages/share';
import Subscribing from '../pages/subscribing';
import JobBoard from '../pages/Settings/jobBoard/index';
import Jobs from '../pages/Jobs';
import RenewJob from '../pages/RenewJob';
import Notifications from '../pages/Notifications';
import PostJob from '../pages/PostJob';
import CompanyAdmins from '../pages/CompanyAdmins';
import CompanyJobs from '../pages/CompanyJobs';
import Impact from '../pages/Impact';
import List from '../pages/list';

export const sessionRoutes = {
  login: {
    path: '/login',
    component: Login,
    name: 'Login',
    visibility: ['public'],
  },
  logout: {
    path: '/logout',
    component: LogOut,
    name: 'Logout',
    visibility: ['public', 'onboarding', 'user'],
  },
  recoverPassword: {
    path: '/recover-password',
    component: RecoverPassword,
    name: 'Recover password',
    visibility: ['public', 'onboarding', 'user'],
  },
  sharedListsSlackSubscribe: {
    path: '/shared-lists/:slug/slack/subscribe',
    component: ContactsSharedListsSlackSubscribe,
    name: 'Shared lists subscribe',
    visibility: ['public', 'onboarding', 'user'],
  },
  sharedListsUnsubscribe: {
    path: '/shared-lists/:slug/unsubscribe',
    component: ContactsSharedListsUnsubscribe,
    name: 'Shared lists unsubscribe',
    visibility: ['public', 'onboarding', 'user'],
  },
};

export const onboardingEmployersRoutes = {
  selectYourCompany: {
    path: '/claim-your-profile/:networkSlug',
    name: 'Your company',
    component: ClaimYourProfile,
    visibility: ['public', 'onboarding', 'user'],
  },
  claimYourProfile: {
    path: '/claim-your-profile/:networkSlug/company/:employerSlug',
    name: 'Claim your profile',
    component: ClaimYourProfile,
    visibility: ['public', 'onboarding', 'user'],
  },
  onboardingEmployer: {
    path: '/onboarding/:networkSlug/company/:employerSlug',
    name: 'Company onboarding',
    component: EmployerOnboarding,
    visibility: ['public', 'onboarding', 'user'],
  },
};

export const userRoutes = {
  accountTab: {
    path: '/account/my-account',
    component: AccountTab,
    name: 'My account',
    icon: SettingsIcon,
    visibility: ['user'],
  },
  profileTab: {
    path: '/account/my-profile',
    component: ProfileTab,
    name: 'My account',
    icon: User,
    visibility: ['user'],
  },
  notifications: {
    path: '/account/notifications',
    component: Notifications,
    name: 'Email Notifications',
    visibility: ['user'],
  },
};

export const networkRoutes = {
  addCompany: addCompanyRoutes,
  analytics: {
    path: '/analytics',
    component: Analytics,
    name: 'Analytics',
    icon: TrendingUp,
  },
  bulkMembersInvite: bulkMembersInviteRoutes,
  companyProfile: {
    path: '/companies/:companySlug',
    component: CompanyProfile,
    name: 'Company profile',
    icon: Layout,
    exact: true,
  },
  companyAdmins: {
    path: '/companies/:companySlug/admins',
    component: CompanyAdmins,
    name: 'Company admins',
    icon: Layout,
    exact: true,
  },
  companyJobs: {
    path: '/companies/:companySlug/jobs',
    component: CompanyJobs,
    name: 'Company jobs',
    icon: Layout,
    exact: true,
  },
  list: {
    path: '/lists',
    component: List,
    name: 'lists',
  },
  listDetail: {
    path: '/list/:listId',
    component: ListDetail,
    name: 'listsDetail',
  },
  contacts: {
    path: '/contacts',
    component: Contacts,
    name: 'contacts',
  },
  contactsAddSingleContact: {
    path: '/contact/add',
    component: ContactsAddSingleContact,
    name: 'Add single contact',
    exact: true,
  },
  contactsAddSingleContactError: {
    path: '/contact/add/error',
    component: ContactsAddSingleContactError,
    name: 'Add single contact',
    exact: true,
  },
  contactsEditSingleContact: {
    path: '/contact/:contactId/edit',
    component: ContactsEditSingleContact,
    name: 'Edit contact',
    exact: true,
  },
  contactsImport: {
    path: '/import-contacts',
    component: ContactsImport,
    name: 'Import contacts',
    exact: true,
  },
  contactsImportSuccess: {
    path: '/import-contacts/success',
    component: ContactsImportSuccess,
    name: 'Import contacts success',
    exact: true,
  },
  contactsImportCsv: {
    path: '/import-contacts/csv',
    component: ContactsImportCsv,
    name: 'Import contacts',
    exact: true,
  },
  contactsUpgrade: {
    path: '/upgrade',
    component: ContactsUpgrade,
    name: 'Upgrade contacts',
    exact: true,
  },
  contactsLinkedInSync: {
    path: '/linkedin/:action',
    component: ContactsLinkedInSync,
    name: 'LinkedIn sync',
    exact: true,
  },
  hires: {
    path: '/hires',
    component: Hires,
    name: 'Hires',
  },
  editJob: {
    path: '/companies/:companyId/:action/:jobId',
    component: Job,
    name: 'Edit job',
    exact: true,
  },
  employers: {
    path: '/companies',
    component: Employers,
    name: 'Companies',
    icon: Briefcase,
    exact: true,
  },
  jobs: {
    path: '/jobs',
    component: Jobs,
    name: 'Jobs',
    icon: Server,
    exact: true,
  },
  networkPostJob: {
    path: '/jobs/post-job',
    component: PostJob,
    name: 'Post a job',
    exact: true,
  },
  renewJob: {
    path: '/jobs/:jobId/renew',
    component: RenewJob,
    name: 'Renew job',
    exact: true,
  },
  networkEditJob: {
    path: '/jobs/:action/:jobId',
    component: PostJob,
    name: 'Edit job',
    exact: true,
  },
  inbox: {
    path: '/inbox',
    component: Inbox,
    name: 'Inbox',
    exact: true,
  },
  postJob: {
    path: '/companies/:companyId/post-job',
    component: Job,
    name: 'Post job',
    exact: true,
  },
  members: memberRoutes,
  membersInvite: {
    path: '/members/invite',
    component: MembersInvite,
    name: 'Invite members',
    icon: Users,
  },
  membersShare: {
    path: '/members/share/email',
    component: Share,
    name: 'Share you talent network',
  },
  networkHome: {
    path: '/',
    component: NetworkHome,
    name: 'NetworkHome',
    exact: true,
  },
  settingsJobBoardAppearance: {
    path: '/settings/job-board/appearance',
    component: JobBoardAppearance,
    name: 'Settings Appearance',
    icon: SettingsIcon,
    exact: true,
  },
  settingsJobBoard: {
    path: '/settings/job-board',
    component: JobBoard,
    name: 'Settings',
    icon: SettingsIcon,
  },
  settingsAdmins: {
    path: '/settings/admins',
    component: Admins,
    name: 'Admins',
    icon: SettingsIcon,
  },
  plansAndBilling: {
    path: '/settings/plans-and-billing',
    component: PlansAndBilling,
    name: 'Plans and billing',
    icon: SettingsIcon,
  },
  settingsContacts: {
    path: '/settings/contacts',
    component: SettingsContacts,
    name: 'SettingsContacts',
    icon: SettingsIcon,
  },
  settings: {
    exact: false,
    component: Settings,
    path: '/settings',
    name: 'Settings',
    icon: SettingsIcon,
  },
  settingsGeneral: {
    path: '/settings/general',
    component: Settings,
    name: 'Settings',
    icon: SettingsIcon,
  },
  membersSubscribing: {
    path: '/members/alerts/subscribing',
    component: Subscribing,
    name: 'Subscribing to your talent network',
  },
  manageSubscriptions: {
    path: '/members/alerts',
    component: ManageSubscriptions,
    name: 'Manage lists',
  },
  home: {
    path: '/home',
    component: Impact,
    name: 'Home',
  },
};
